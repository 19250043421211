.dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 15px;
    border-width: 2px;
    border-radius: 5px;
    border-color: #0064b0;
    border-style: dashed;
    background-color: #fff;
    color: #6a6a6a;
    outline: none;
    transition: border 0.24s ease-in-out;
    margin-bottom: 20px;
    font-size:12px;
    height:200px;
  }

  .dropzone:hover {
    border-color: #2196f3
  }
  
  .dropzone:active {
    border-color: #ff0000;
  }
  
  .dropzone.disabled {
    opacity: 0.6;
  }

  .callout {
    border-color: #fff;
    margin-bottom: 0px;
  }
  
  .callout.secondary {
    background-color: #fff;
    color: #0a0a0a;
    width: 100%;
    overflow-y: scroll;
  }

  .filesContainer {
    margin-left: 12px;
    margin-top: 12px;
    margin-right: 20px;
    margin-bottom: 25px;
  }

  .fileStatus {
    margin-bottom:16px;
  }

  .fileText {
    color: #2196f3;
    font-weight: 400;
    margin-top:20px;
    cursor: pointer;
  }